import cx from 'classnames'
import { useEffect, useRef } from 'react'

import styles from './Dialog.module.scss'

const Dialog = ({
  id,
  onDismiss,
  className,
  children,
}: {
  id?: string
  onDismiss?: () => void
  className?: string
  children: React.ReactNode
}) => {
  const modalRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    const dialog = modalRef.current
    if (!dialog) return

    dialog.showModal()
    return () => dialog.close()
  }, [])

  useEffect(() => {
    const dialog = modalRef.current
    if (!dialog) return
    const handleClose = () => onDismiss?.()

    dialog.addEventListener('close', handleClose)
    return () => {
      dialog.removeEventListener('close', handleClose)
    }
  }, [onDismiss])

  return (
    <dialog
      id={id}
      ref={modalRef}
      aria-modal
      className={cx(styles.dialog, className)}
    >
      {children}
    </dialog>
  )
}

export default Dialog
