import React from 'react'

import { Text, Title } from '@mondough/monzo-ui'

import styles from './AnnouncementDialog.module.scss'
import { AnnouncementDialogProps } from './AnnouncementDialog.types'
import { Button, Dialog } from '..'

const AnnouncementDialog: React.FC<AnnouncementDialogProps> = ({
  id,
  title,
  description,
  assetSrc,
  ctaLabel,
  onPrimaryAction,
}: AnnouncementDialogProps) => {
  return (
    <Dialog
      id={id}
      className={styles['announcement-dialog']}
      onDismiss={onPrimaryAction}
    >
      <img src={assetSrc} alt="" className={styles.image} height={206} />
      <Title
        is="h1"
        size="large"
        align="center"
        marginBottom="small"
        className={styles.title}
      >
        {title}
      </Title>
      <Text className={styles.description} marginBottom="large">
        {description}
      </Text>
      <Button onClick={onPrimaryAction} theme="primary" fullWidth>
        {ctaLabel}
      </Button>
    </Dialog>
  )
}

export default AnnouncementDialog
