import { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'

import {
  buildActionAnalyticsAttributes,
  getClient,
} from '@mondough/analytics-v2'
import { ButtonGroup, Text, Title } from '@mondough/monzo-ui'

import { useFormatMessage } from '../../hooks'
import { TabContext } from '../../providers/TabProvider'
import styles from './LeaveTabDialog.module.scss'
import { Button, Dialog } from '..'

const LeaveTabDialog = ({
  setShowDialog,
  onLeave,
}: {
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>
  onLeave: () => void
}) => {
  const formatMessage = useFormatMessage()
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { refreshTab } = useContext(TabContext)

  return (
    <Dialog
      id="leave-tab-dialog"
      onDismiss={() => {
        setShowDialog(false)
        setError(null)
        const analyticsClient = getClient()
        if (!analyticsClient) return
        analyticsClient.trackAction({
          name: 'dismiss',
          type: 'click',
        })
      }}
      className={styles['leave-tab-dialog']}
    >
      <Title align="center" marginBottom="small">
        {formatMessage('leave.dialog.title')}
      </Title>
      <Text marginBottom="extra-small">
        {formatMessage('leave.dialog.subtitle')}
      </Text>
      {error && (
        <Text color="destructive" marginBottom="small" className={styles.error}>
          {error}
        </Text>
      )}
      <ButtonGroup direction="vertical" className={styles['button-group']}>
        <Button
          theme="secondary"
          fullWidth
          onClick={() => {
            setShowDialog(false)
            setError(null)
          }}
          {...buildActionAnalyticsAttributes('back')}
        >
          {formatMessage('leave.dialog.button.back')}
        </Button>
        <Button
          fullWidth
          id="leave-tab-confirm"
          theme="destructive"
          onClick={async () => {
            try {
              setLoading(true)
              await onLeave()
              setLoading(false)
              await refreshTab()
              setShowDialog(false)
              await router.push('/')
            } catch (error) {
              setLoading(false)
              setError(formatMessage('leave.dialog.error'))
            }
          }}
          loading={loading}
          {...buildActionAnalyticsAttributes('leave')}
        >
          {formatMessage('leave.dialog.button.leave')}
        </Button>{' '}
      </ButtonGroup>
    </Dialog>
  )
}

export default LeaveTabDialog
