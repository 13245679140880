import React, { useState } from 'react'

import { useFormatMessage } from '../hooks'
import { NUMBER_OF_CUSTOMERS_MILLIONS } from '../utils'
import { TabCreatorReferral } from '../utils'

export const ReferralContext = React.createContext({
  referralData: null as TabCreatorReferral | null,
  setReferral: (_referral: TabCreatorReferral | null) => {
    return
  },
  defaultReferralText: '' as string,
})

export const ReferralProvider = ({ children }: { children: JSX.Element }) => {
  const [referralData, setReferralData] = useState<TabCreatorReferral | null>(
    null,
  )
  const formatMessage = useFormatMessage()
  const defaultReferralText = formatMessage('referral.default.description', {
    numberOfMillionCustomers: NUMBER_OF_CUSTOMERS_MILLIONS,
  })

  return (
    <ReferralContext.Provider
      value={{
        referralData,
        setReferral: setReferralData,
        defaultReferralText,
      }}
    >
      {children}
    </ReferralContext.Provider>
  )
}
