import 'what-input'
import '@mondough/monzo-ui/src/tokens/themes.scss'
import '@mondough/monzo-ui/src/components/dialog/dialog-globals.scss'

import '../scss/globals.scss'

import { AppContext, AppProps } from 'next/app'
import React from 'react'
import { IntlProvider } from 'react-intl'

import {
  NextPageAnalytics,
  getClient as getAnalyticsClient,
  useNextScreenTracking,
} from '@mondough/analytics-v2'
import { MonzoUiThemeProvider, Theme } from '@mondough/monzo-ui'
import { initErrorHandling } from '@mondough/next-utils'
import { displayExternalSelfXSSWarning } from '@mondough/self-xss-warning'

import { ErrorBoundary, Layout } from '../components'
import { enGB } from '../copy/en-GB'
import { enGBSplit } from '../copy/en-GB-split'
import { AuthProvider, ReferralProvider, TabProvider } from '../providers'
import {
  fetchClientTokenSSR,
  generateHashedFingerprint,
  getCookieConsent,
  getRenameFlag,
  ignoredErrors,
  initializeServerSideLogging,
  legacyCacheName,
  legacyTimeStoreCacheName,
  setupAnalytics,
} from '../utils'

type InitialProps = {
  hashedFingerprint?: string | null
  renameFlag?: boolean
}
interface AppPropsWithAnalytics extends AppProps<InitialProps> {
  analytics: NextPageAnalytics
}

// Delete legacy cache stores which contained profile image data
// We shouldn't be caching these anymore as reported in #inc-customer-image-accessible-after-profile-privacy-disabled-2025-01-31
async function deleteOldCacheStores() {
  const cacheStoreNames = [legacyCacheName, legacyTimeStoreCacheName]
  if (!window.caches) {
    // Caching API not supported
    return
  }
  for (const cacheStoreName of cacheStoreNames) {
    await window.caches.delete(cacheStoreName)
  }
}

const App = (props: AppPropsWithAnalytics) => {
  const { Component, pageProps } = props

  const [renameFlag, setRenameFlag] = React.useState<boolean>(
    pageProps.renameFlag ?? false,
  )
  const ranSetup = React.useRef(false)

  const LOCALE = 'en-GB'
  const messages = renameFlag ? enGBSplit : enGB
  const intlProviderKey = renameFlag ? 'en-GB-split' : 'en-GB'

  useNextScreenTracking(props)
  initializeServerSideLogging()
  React.useEffect(() => {
    const setup = async () => {
      displayExternalSelfXSSWarning()
      initErrorHandling(undefined, ignoredErrors, 'app.tabs-monzo-me')
      if (getCookieConsent() === true && !getAnalyticsClient()) {
        await setupAnalytics()
      }
      await deleteOldCacheStores()

      //Fetch rename flag from the client side
      const renameFlagResponse = await getRenameFlag()
      setRenameFlag(renameFlagResponse)
    }
    if (!ranSetup.current) {
      void setup()
      ranSetup.current = true
    }
  }, [])

  return (
    <MonzoUiThemeProvider initialTheme={Theme.LightRefreshed}>
      <ErrorBoundary>
        <AuthProvider hashedFingerprint={pageProps.hashedFingerprint ?? null}>
          <IntlProvider
            key={intlProviderKey} // Force re-render when rename flag changes
            locale={LOCALE}
            messages={messages}
            defaultLocale={LOCALE}
          >
            <ReferralProvider>
              <TabProvider>
                <Layout {...pageProps} renameFlag={renameFlag}>
                  <Component {...pageProps} />
                </Layout>
              </TabProvider>
            </ReferralProvider>
          </IntlProvider>
        </AuthProvider>
      </ErrorBoundary>
    </MonzoUiThemeProvider>
  )
}

App.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps: InitialProps = {}
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  if (ctx.req) {
    const clientAuthToken = await fetchClientTokenSSR()
    const renameFlag = await getRenameFlag(clientAuthToken)
    try {
      pageProps = {
        ...pageProps,
        hashedFingerprint: await generateHashedFingerprint(ctx.req),
        renameFlag,
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching hashed fingerprint', error)
    }
  }
  return { pageProps }
}

export default App
