import cx from 'classnames'
import React from 'react'

import { PlainCard, Text, TextInput, Title } from '@mondough/monzo-ui'

import { useFormatMessage } from '../../hooks'
import { AvatarWithBadge, Button } from '../'
import styles from './ActionCard.module.scss'
import { ActionCardProps } from './ActionCard.types'

const ActionCard: React.FC<ActionCardProps> = ({
  id,
  avatar,
  assetNode,
  title,
  text,
  primaryButton,
  secondaryButton,
  textInput,
  errorMessage,
  withGuestHeader,
  children,
  className,
}: ActionCardProps) => {
  const formatMessage = useFormatMessage()
  return (
    <PlainCard className={cx(styles.card, className)} as="section" id={id}>
      {withGuestHeader && (
        <>
          <Text
            align="center"
            marginBottom="small"
            className={styles['guest-header-pill']}
          >
            {formatMessage('guest.header.pill')}
          </Text>
          <Title
            is="h1"
            marginTop="small"
            marginBottom="small"
            align="center"
            className={styles['guest-header-title']}
          >
            {formatMessage('guest.header.title')}
          </Title>
        </>
      )}
      {avatar && (
        <AvatarWithBadge
          size="extra-large"
          className={styles.avatar}
          {...avatar}
        />
      )}
      {assetNode}
      {title && (
        <Title
          is={withGuestHeader ? 'h2' : 'h1'}
          size="small"
          marginTop="large"
          marginBottom="small"
          align="center"
          className={styles.title}
        >
          {title}
        </Title>
      )}
      {text &&
        text.split('\n').map((line, i) => (
          <Text
            key={`line-${i}`}
            size="small"
            align="center"
            marginLeft="large"
            marginRight="large"
            marginBottom="medium"
            className={styles.text}
          >
            {line}
          </Text>
        ))}
      {textInput && (
        <>
          <label htmlFor={textInput.id} className={styles.label}>
            {textInput.label}
          </label>
          <TextInput
            fullWidth
            className={styles['text-input']}
            {...textInput}
          />
        </>
      )}
      {errorMessage && (
        <Text
          size="small"
          color="red1"
          className={styles.error}
          marginBottom="small"
        >
          {errorMessage}
        </Text>
      )}
      {children}
      {primaryButton && (
        <Button
          id={`primary-button-${id}`}
          fullWidth
          theme="primary"
          className={styles.primary}
          marginBottom="small"
          {...primaryButton}
        >
          {primaryButton.text}
        </Button>
      )}
      {secondaryButton && (
        <Button
          id={`secondary-button-${id}`}
          fullWidth
          theme="secondary"
          className={styles.secondary}
          {...secondaryButton}
        >
          {secondaryButton.text}
        </Button>
      )}
    </PlainCard>
  )
}

export default ActionCard
