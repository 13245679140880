import cx from 'classnames'
import { useRouter } from 'next/router'
import * as React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Text, Title } from '@mondough/monzo-ui'

import { useFormatMessage } from '../../../hooks'
import { ReferralContext } from '../../../providers'
import questionmarkIcon from '../../../public/images/icons/questionmark.circle.svg'
import toyIcon from '../../../public/images/icons/toy.svg'
import bba from '../../../public/images/trust/bba.png'
import cass from '../../../public/images/trust/cass.svg'
import fscs from '../../../public/images/trust/fscs.svg'
import { NUMBER_OF_CUSTOMERS_MILLIONS, getCDNSrc } from '../../../utils'
import { AppStoreButton, Button } from '../../'
import styles from './MultipleBlockBanner.module.scss'

const Block = ({
  iconSrc,
  title,
  text,
  buttonText,
  buttonHref,
  openInNewTab,
  analyticsName,
  shouldDisplay = true,
  className,
}: {
  iconSrc: string
  title: string
  text: string
  buttonText: string
  buttonHref: string
  openInNewTab?: boolean
  analyticsName: string
  shouldDisplay?: boolean
  className?: string
}) => {
  const router = useRouter()

  return shouldDisplay ? (
    <article className={cx([styles.block, className])}>
      <Title is="h2" className={styles.title}>
        <img className={styles.icon} src={iconSrc} alt="" />
        {title}
      </Title>
      {text.split('\n').map((line) => (
        <Text key={line} className={styles.text}>
          {line}
        </Text>
      ))}
      <Button
        theme="primary"
        className={styles.button}
        onClick={async () => await router.push(buttonHref)}
        target={openInNewTab ? '_blank' : undefined}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
        {...buildActionAnalyticsAttributes(analyticsName)}
      >
        {buttonText}
      </Button>
    </article>
  ) : null
}

const MultipleBlockBanner = () => {
  const { referralData } = React.useContext(ReferralContext)
  const router = useRouter()
  const formatMessage = useFormatMessage()

  return (
    <div className={styles.inner}>
      <Block
        iconSrc={getCDNSrc(toyIcon)}
        title={formatMessage('marketing.banner-discover.title')}
        text={formatMessage('marketing.banner-discover.description', {
          numberOfMillionCustomers: NUMBER_OF_CUSTOMERS_MILLIONS,
        })}
        buttonText={formatMessage('marketing.banner-discover.button')}
        buttonHref="https://monzo.com/"
        openInNewTab={true}
        analyticsName="banner-discover"
        className={referralData ? 'desktop-only' : 'mobile-only'}
      />
      <Block
        iconSrc={getCDNSrc(questionmarkIcon)}
        title={formatMessage('marketing.banner-faq.title')}
        text={formatMessage('marketing.banner-faq.text')}
        buttonText={formatMessage('marketing.banner-faq.button')}
        buttonHref="/faq"
        analyticsName="banner-faq"
        shouldDisplay={!router.pathname.includes('faq')}
      />
      <article className={cx([styles['download-block'], 'mobile-only'])}>
        <Title
          is="h2"
          color="white"
          size="large"
          align="center"
          className={styles.title}
        >
          {formatMessage('marketing.download-block.title')}
        </Title>
        <div className={styles['app-buttons']}>
          <AppStoreButton href="https://monzo.com/download" store="app-store" />
          <AppStoreButton
            href="https://monzo.com/download"
            store="google-play"
          />
        </div>
      </article>

      <ul className={cx([styles['trust-grid'], 'mobile-only'])}>
        <li>
          <img
            src={getCDNSrc(bba)}
            alt={formatMessage('marketing.trust-grid.bba.alt')}
          />
        </li>
        <li>
          <img
            src={getCDNSrc(cass)}
            alt={formatMessage('marketing.trust-grid.cass.alt')}
          />
        </li>
        <li>
          <img
            src={getCDNSrc(fscs)}
            alt={formatMessage('marketing.trust-grid.fscs.alt')}
          />
        </li>
      </ul>
    </div>
  )
}

export default MultipleBlockBanner
