import { differenceInSeconds, isYesterday } from 'date-fns'
import { detect } from 'detect-browser'
import getNavigatorLanguages from 'navigator-languages'
import { v4 as uuid } from 'uuid'

import { captureExceptionWithInfo } from '@mondough/error-handling'

import {
  ACTION_EVENT_TYPE_FRIENDLY_NAMES,
  ANALYTICS_VERSION,
  CURRENT_LIFECYCLE_KEY,
  DEFAULT_SESSION_LENGTH_IN_SECONDS,
  MAX_REQUEST_ATTEMPTS,
  REQUEST_RETRY_DELAY_IN_MS,
  SESSION_ID_KEY,
  SESSION_ID_NAMESPACE,
  TAB_ID_KEY,
  TAB_ID_NAMESPACE,
} from './constants'
import sendEventToAPI from './sendEventToAPI'
import { ActionAnalyticsAttributes } from './types'
import {
  ActionType,
  AnalyticsEvent,
  AnalyticsRequestBody,
  ClientConfig,
  DomActionEventType,
  EventReference,
  EventType,
  NonNullValue,
  Parameters,
  SessionReference,
  TabReference,
} from './types'
import {
  browserIsCompatible,
  convertStringToValue,
  isBlank,
  keyEventIsTrackable,
  stripDecorationFromScreenEventName,
} from './utils'

const TRACKED_ACTION_TAG_NAMES = ['a', 'button']

class AnalyticsClient {
  appName: string
  sessionLength: number
  apiUrl: string
  accessToken: string
  userId: string | null | undefined
  anonId: string | null | undefined
  urlSanitizer: (arg0: string) => string
  currentScreen:
    | (EventReference & {
        customParameters?: Parameters
        tags?: ReadonlyArray<string>
      })
    | null
    | undefined

  siblingScreen: EventReference | null | undefined
  siblingAction: EventReference | null | undefined
  defaultParameters: Parameters
  haveBegunTrackingDOMEvents: boolean
  runOnClientShutdown: Array<() => void>
  options?: {
    enableBacklog?: boolean
  }
  onEventUpload?: (event: AnalyticsRequestBody) => void

  constructor({
    appName,
    apiUrl,
    accessToken,
    userId,
    anonId,
    appVersion,
    sessionLength,
    options,
    urlSanitizer = (url) => url,
    onEventUpload,
  }: ClientConfig) {
    this.appName = appName
    this.sessionLength = sessionLength ?? DEFAULT_SESSION_LENGTH_IN_SECONDS
    this.apiUrl = apiUrl
    this.accessToken = accessToken
    this.userId = userId
    this.anonId = anonId
    this.options = options
    this.urlSanitizer = urlSanitizer
    this.currentScreen = undefined
    this.siblingScreen = undefined
    this.siblingAction = undefined
    this.haveBegunTrackingDOMEvents = false
    this.runOnClientShutdown = []
    this.onEventUpload = onEventUpload
    const deviceInfo = detect()
    this.defaultParameters = {
      browser_user_agent: navigator?.userAgent ?? 'unknown',
      browser: deviceInfo?.name ?? 'unknown',
      browser_version: deviceInfo?.version ?? 'unknown',
      operating_system: deviceInfo?.os ?? 'unknown',
      device_accepted_languages: getNavigatorLanguages(),
      device_time_zone: new Date().getTimezoneOffset() / 60,
      app_version: appVersion,
      url: window.location.pathname,
      viewport_width: window.document.documentElement.clientWidth,
      viewport_height: window.document.documentElement.clientHeight,
    }

    this.startLifecycleTracking()
    this.startActionTracking()
    this.haveBegunTrackingDOMEvents = true
  }

  getCurrentLifecycle(): EventReference | null | undefined {
    const stringifiedData = sessionStorage.getItem(CURRENT_LIFECYCLE_KEY)
    if (isBlank(stringifiedData)) return
    // $FlowIgnore - lib.utils TypeScript migration https://github.com/monzo/web-projects/pull/11788
    return JSON.parse(stringifiedData)
  }

  setCurrentLifecycle(event: EventReference) {
    sessionStorage.setItem(CURRENT_LIFECYCLE_KEY, JSON.stringify(event))
  }

  addDefaultParameter(key: string, value: NonNullValue) {
    // no point setting it again if it already is and equals the same value
    if (this.defaultParameters[key] === value) {
      return
    }
    this.defaultParameters = { ...this.defaultParameters, [key]: value }
  }

  clearCurrentLifecycle() {
    sessionStorage.removeItem(CURRENT_LIFECYCLE_KEY)
  }

  getSession(): SessionReference {
    return this.getActiveSession() ?? this.startNewSession()
  }

  getTab(): TabReference {
    return this.getActiveTab() ?? this.startNewTab()
  }

  startActionTracking(): void {
    if (this.haveBegunTrackingDOMEvents) {
      return
    }

    const handleMouseEvent = (event: MouseEvent) =>
      this.handleActionEvent('mousedown', event)
    const handleKeyEvent = (event: KeyboardEvent) =>
      this.handleActionEvent('keydown', event)

    window.document.addEventListener('mousedown', handleMouseEvent)
    window.document.addEventListener('keydown', handleKeyEvent)

    const removeActionEventListeners = () => {
      window.document.removeEventListener('mousedown', handleMouseEvent)
      window.document.removeEventListener('keydown', handleKeyEvent)
    }
    this.runOnClientShutdown.push(removeActionEventListeners)
  }

  handleActionEvent(
    type: DomActionEventType,
    event: KeyboardEvent | MouseEvent,
  ) {
    const targetElement = event.target as HTMLElement
    if (typeof targetElement.closest === 'undefined') {
      // This browser (likely IE11) doesn't support `closest()` DOM API. And we don't want it to error.
      // We could polyfill this but considering we or Microsoft don't officially support IE11,
      // and analytics is a nice-to-have in most cases. We won't polyfill.
      // If we change our mind and want analytics-v2 to support IE11, then we'll possibly have
      // more work to do than just polyfilling `closest()`
      return
    }

    // Our event is attached to the whole document so we can't use 'currentTarget' to get the actual element
    // which has the event listener attached because it'll return the whole document. We also can't use 'target'
    // because that'll likely not be the actual element that has the event listener attached but instead a child of it.
    // So instead we find the closest element that we know we're tracking action events for. This is a pretty safe
    // assumption because:
    // - We should only be attaching click event listeners to interactive elements (e.g. `a` or `button`).
    // - We shouldn't be nesting interactive elements (such as a `button` in and `a`) that would mean we accidentally
    //   target the wrong one.
    const closestTrackedElement: HTMLElement | null = targetElement.closest(
      TRACKED_ACTION_TAG_NAMES.join(', '),
    )

    if (closestTrackedElement == null) {
      return
    }

    // Drop any keyboard events that aren't the equivalent of clicking an interactive element. We may care about capturing
    // other keyboard events in the future but currently the purpose of an 'action' event is to track user actions that
    // have an effect on the app (e.g. they open a new screen or submit a form).
    if (
      type === 'keydown' &&
      !keyEventIsTrackable(event as KeyboardEvent, closestTrackedElement)
    ) {
      return
    }

    if (
      closestTrackedElement.hasAttribute(
        ActionAnalyticsAttributes.ACTION_NO_TRACKING,
      ) ||
      closestTrackedElement.closest(
        `*[${ActionAnalyticsAttributes.ACTION_NO_TRACKING}]`,
      ) != null
    ) {
      return
    }

    const actionID: string | null = closestTrackedElement.getAttribute(
      ActionAnalyticsAttributes.ACTION_TRACKING_NAME,
    )

    if (isBlank(actionID)) {
      const errorMsg = `Element is missing a value for attribute '${ActionAnalyticsAttributes.ACTION_TRACKING_NAME}' or should have the '${ActionAnalyticsAttributes.ACTION_NO_TRACKING}' attribute`
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(errorMsg, closestTrackedElement)
      }

      // If we ever make this into a shared library we potentially want to allow the app itself to define what happens with errors.
      // For now, coupling it with our error tracking library doesn't feel particularly dangerous or hard to revert.
      captureExceptionWithInfo(new Error(errorMsg), {
        nodeName: closestTrackedElement.nodeName,
        classes: closestTrackedElement.getAttribute('class'),
        href: closestTrackedElement.getAttribute('href')?.split('?')[0], // Remove the parameters from the href so we don't risk tracking sensitive info
      })
      return
    }

    const friendlyActionType = ACTION_EVENT_TYPE_FRIENDLY_NAMES[type]

    // Get any parameter data available for this action from the attributes on the tracked elements. This is represent on the element
    // as 'data-monzoanalytics-action-parameters="some_data=test"'. This is limited in that a key can only have a single value.
    // There are more complex solutions that can solve that but for now I'm keeping the solution simple until we know we need the complexity.
    const customParameters: { [key: string]: NonNullValue } = {
      action_type: friendlyActionType,
    }

    const parametersAttribute = closestTrackedElement.getAttribute(
      ActionAnalyticsAttributes.ACTION_TRACKING_PARAMETERS,
    )

    if (!isBlank(parametersAttribute)) {
      parametersAttribute?.split('&').forEach((parameterString) => {
        const [key, valueAsString] = parameterString.split(/=(.+)/)
        const value = valueAsString
          ? convertStringToValue(valueAsString)
          : convertStringToValue('')
        if (value == null) return
        if (key) {
          customParameters[key] = value
        }
      })
    }

    this.trackAction({
      name: actionID || '',
      type: friendlyActionType,
      customParameters,
    })
  }

  startLifecycleTracking() {
    if (this.haveBegunTrackingDOMEvents) {
      return
    }

    const handleEvent = (type: 'foreground' | 'background') => {
      const id = `lifecycle_${uuid()}`
      const name = type
      const timestamp = new Date().toISOString()
      this.updateSession(timestamp)
      const event = this.buildRequestBody({
        name,
        type: 'lifecycle',
        id,
        timestamp,
        sibling: this.getCurrentLifecycle(),
      })

      this.recordEvent(event)

      this.setCurrentLifecycle({
        name: event.name,
        id,
        timestamp,
      })

      if (type !== 'foreground') {
        return
      }
      this.siblingScreen = null
      this.siblingAction = null

      // We should re-trigger an event for the current screen when a new lifecycle starts.
      if (this.currentScreen != null) {
        this.trackScreen({
          customParameters: this.currentScreen.customParameters,
          tags: [...(this.currentScreen.tags ?? [])],
          name: stripDecorationFromScreenEventName(
            this.currentScreen.name,
            this.appName,
          ),
        })
      }
    }

    const handleVisibilityChange = () => {
      const isHidden = document.visibilityState === 'hidden'
      if (isHidden) {
        handleEvent('background')
        return
      }

      handleEvent('foreground')
    }

    window.document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false,
    )

    const removeVisibilityEventListeners = () => {
      window.document.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        false,
      )
    }
    this.runOnClientShutdown.push(removeVisibilityEventListeners)

    handleEvent('foreground')
  }

  clearSession() {
    localStorage.removeItem(SESSION_ID_KEY)
  }

  getActiveSession(): SessionReference | null | undefined {
    const activeSessionStringified = localStorage.getItem(SESSION_ID_KEY)
    if (activeSessionStringified == null) {
      return null
    }

    const activeSession = JSON.parse(activeSessionStringified)
    const sessionHasTimedOut =
      differenceInSeconds(new Date(), new Date(activeSession.timestamp)) >=
      this.sessionLength

    if (sessionHasTimedOut || isYesterday(new Date(activeSession.timestamp))) {
      return this.startNewSession()
    }
    return activeSession
  }

  startNewSession(): SessionReference {
    const newSessionId = `${SESSION_ID_NAMESPACE}_${uuid()}`
    const sessionTimestamp = new Date().toISOString()
    const newSession = { id: newSessionId, timestamp: sessionTimestamp }
    localStorage.setItem(SESSION_ID_KEY, JSON.stringify(newSession))
    this.clearSiblingScreen()
    this.clearCurrentLifecycle()
    this.siblingAction = null
    this.siblingScreen = null

    const id = `lifecycle_${uuid()}`
    const timestamp = new Date().toISOString()

    const event = this.buildRequestBody({
      name: 'session_start',
      type: 'lifecycle',
      id,
      timestamp,
      sibling: null,
    })

    this.recordEvent(event)
    this.setCurrentLifecycle({
      name: event.name,
      id,
      timestamp,
    })

    return newSession
  }

  updateSession(timestamp: string): SessionReference {
    const updatedSession = {
      ...(this.getActiveSession() ?? this.startNewSession()),
      timestamp,
    }

    localStorage.setItem(SESSION_ID_KEY, JSON.stringify(updatedSession))
    return updatedSession
  }

  getActiveTab(): TabReference | null | undefined {
    const activeTab = sessionStorage.getItem(TAB_ID_KEY)
    if (activeTab == null) {
      return null
    }
    return JSON.parse(activeTab)
  }

  startNewTab(): TabReference {
    const newTab = {
      id: `${TAB_ID_NAMESPACE}_${uuid()}`,
      timestamp: new Date().toISOString(),
    }

    sessionStorage.setItem(TAB_ID_KEY, JSON.stringify(newTab))
    return newTab
  }

  trackScreen({ name, customParameters = {}, tags }: AnalyticsEvent) {
    const id = `screen_${uuid()}`
    const timestamp = new Date().toISOString()
    this.updateSession(timestamp)
    const sibling =
      this.siblingScreen !== null ? this.siblingScreen : this.getSiblingScreen()
    const event = this.buildRequestBody({
      type: 'screen',
      name,
      id,
      timestamp,
      customParameters,
      tags,
      sibling,
      includeParentLifecycle: true,
    })

    this.recordEvent(event)
    const eventRef = {
      id,
      name: event.name,
      timestamp,
    }

    this.currentScreen = {
      ...eventRef,
      customParameters,
      tags,
    }
    // Store sibling screen in session storage so we can use it for the next screen event
    // this is useful if we navigate via an absolute url and still want to know the internal referring page.
    this.setSiblingScreen({ ...eventRef })
  }

  setSiblingScreen(event: EventReference) {
    this.siblingScreen = event
    const tab = this.getActiveTab()
    if (tab == null) return

    sessionStorage.setItem(`siblingScreen_${tab.id}`, JSON.stringify(event))
  }
  getSiblingScreen() {
    const tab = this.getActiveTab()
    if (tab == null) return null

    const event = sessionStorage.getItem(`siblingScreen_${tab.id}`)
    if (event == null) return null

    return JSON.parse(event) as EventReference
  }
  clearSiblingScreen() {
    const tab = this.getActiveTab()
    if (tab == null) return

    sessionStorage.removeItem(`siblingScreen_${tab.id}`)
  }
  trackAction({
    name,
    type,
    customParameters = {},
    tags,
  }: AnalyticsEvent & {
    type: ActionType
  }) {
    const id = `action_${uuid()}`

    const currentScreenName =
      this.currentScreen != null
        ? stripDecorationFromScreenEventName(
            this.currentScreen.name,
            this.appName,
          )
        : 'no-parent-screen'
    const fullEventName = `${currentScreenName}.${name}.${type}`
    const timestamp = new Date().toISOString()
    this.updateSession(timestamp)
    const event = this.buildRequestBody({
      name: fullEventName,
      type: 'action',
      id,
      timestamp,
      sibling: this.siblingAction,
      customParameters: { ...customParameters, action_type: type },
      tags,
      includeParentLifecycle: true,
      includeParentScreen: true,
    })

    this.recordEvent(event)
    this.siblingAction = {
      id,
      name: event.name,
      timestamp,
    }
  }

  trackView({ name, customParameters, tags }: AnalyticsEvent) {
    const id = `view_${uuid()}`
    const timestamp = new Date().toISOString()
    this.updateSession(timestamp)
    const event = this.buildRequestBody({
      name: name,
      type: 'view',
      id,
      timestamp,
      customParameters,
      tags,
      includeParentLifecycle: true,
      includeParentScreen: true,
    })

    this.recordEvent(event)
  }

  trackData({ name, customParameters, tags }: AnalyticsEvent) {
    const id = `data_${uuid()}`
    const timestamp = new Date().toISOString()
    const event = this.buildRequestBody({
      name,
      type: 'data',
      id,
      timestamp,
      customParameters,
      tags,
      includeLastAction: true,
      includeLastScreen: true,
      includeLastLifecycle: true,
    })

    this.recordEvent(event)
  }

  trackError({
    name,
    tags,
    customParameters,
    errorMessage,
  }: AnalyticsEvent & {
    errorMessage: string
  }) {
    const id = `error_${uuid()}`
    const timestamp = new Date().toISOString()
    const event = this.buildRequestBody({
      name,
      type: 'error',
      id,
      timestamp,
      customParameters: { ...customParameters, error_message: errorMessage },
      tags,
      includeLastAction: true,
      includeLastScreen: true,
      includeLastLifecycle: true,
    })

    this.recordEvent(event)
  }

  updateAccessToken(token: string) {
    this.accessToken = token
  }

  updateUserId(userId: string) {
    this.userId = userId
  }

  clearUserId() {
    this.userId = null
  }

  updateAnonId(anonId: string) {
    this.anonId = anonId
  }

  clearAnonId() {
    this.anonId = null
  }

  buildDefaultParameters(): Parameters {
    return {
      ...this.defaultParameters,
      url: this.urlSanitizer(window.location.pathname),
      viewport_width: window.document.documentElement.clientWidth,
      viewport_height: window.document.documentElement.clientHeight,
    }
  }

  buildRequestBody(
    event: AnalyticsEvent & {
      type: EventType
      id: string
      timestamp: string
      sibling?: EventReference | null | undefined
      includeParentScreen?: boolean
      includeParentLifecycle?: boolean
      includeLastScreen?: boolean
      includeLastAction?: boolean
      includeLastLifecycle?: boolean
    },
  ): AnalyticsRequestBody {
    const currentLifecycle = this.getCurrentLifecycle()
    const session = this.getSession()
    const tab = this.getTab()
    const {
      type,
      name,
      id,
      timestamp,
      sibling,
      includeParentScreen = false,
      includeParentLifecycle = false,
      includeLastScreen = false,
      includeLastAction = false,
      includeLastLifecycle = false,
      customParameters,
    } = event

    // Flow is making me explicitly declare the type for 'tags' as Array<string> because it
    // thinks the inferred type is ambiguous, so could be Array<string> or Array<number>.
    // I'm not sure why. Guess it's just another Flow thing ¯\_(ツ)_/¯
    const tags: Array<string> = event.tags != null ? [...event.tags] : []

    const body: AnalyticsRequestBody = {
      name: `${type}.${this.appName}.${name}`,
      data: {
        version: ANALYTICS_VERSION,

        client_session_id: session.id,
        client_session_timestamp: session.timestamp,
        client_tab_id: tab.id,
        client_tab_timestamp: tab.timestamp,
        client_event_id: id,
        client_timestamp: timestamp,
        user_id: this.userId,
        anon_id: this.anonId,
        type: type,
        parameters: {
          ...this.buildDefaultParameters(),
          ...customParameters,
          tags,
        },
      },
    }

    // Lets spread all the things. We don't want these objects to have the same reference as is
    // stored in memory and end up accidentally mutating to incorrect values before making their
    // way to our API
    if (includeParentLifecycle) {
      body.data.parent_lifecycle =
        currentLifecycle != null ? { ...currentLifecycle } : null
    }
    if (includeParentScreen) {
      body.data.parent_screen =
        this.currentScreen != null
          ? {
              id: this.currentScreen.id,
              name: this.currentScreen.name,
              timestamp: this.currentScreen.timestamp,
            }
          : null
    }
    if (includeLastScreen) {
      body.data.last_screen =
        this.currentScreen != null
          ? {
              id: this.currentScreen.id,
              name: this.currentScreen.name,
              timestamp: this.currentScreen.timestamp,
            }
          : null
    }
    if (includeLastAction) {
      body.data.last_action =
        this.siblingAction != null ? { ...this.siblingAction } : null
    }
    if (includeLastLifecycle) {
      body.data.last_lifecycle =
        currentLifecycle != null ? { ...currentLifecycle } : null
    }
    if (event.hasOwnProperty('sibling')) {
      body.data.sibling = sibling != null ? { ...sibling } : null
    }

    return body
  }

  async recordEvent(event: AnalyticsRequestBody, attempts?: number) {
    attempts = attempts ? attempts : 0
    try {
      const delay =
        attempts != null && attempts > 0
          ? REQUEST_RETRY_DELAY_IN_MS * attempts
          : 0

      if (this.onEventUpload) this.onEventUpload(event)

      await sendEventToAPI({
        apiUrl: this.apiUrl,
        accessToken: this.accessToken,
        event,
        delay,
      })
    } catch (error) {
      const eventRef = {
        name: event.name,
        id: event.data.client_event_id,
        timestamp: event.data.client_timestamp,
      }

      if (attempts != null && attempts >= MAX_REQUEST_ATTEMPTS) {
        // eslint-disable-next-line no-console
        console.error(
          'Dropped analytics event. Retry limit exceeded.',
          error,
          eventRef,
        )

        return
      }
      this.recordEvent(event, attempts + 1)
    }
  }
}

export type AnalyticsClientType = AnalyticsClient

let client: AnalyticsClientType | null | undefined

export const getClient = () => client

// This is mostly for the purposes of testing, so that we can start with a fresh client for each test case
export const destroyClient = () => {
  try {
    localStorage.removeItem(SESSION_ID_KEY)
    sessionStorage.removeItem(CURRENT_LIFECYCLE_KEY)
    sessionStorage.removeItem(TAB_ID_KEY)
  } catch (error) {
    // Do nothing here. These storage APIs aren't supported by the user's browser. So we've never added anything
    // that needs removing.
  }

  const currentClient = getClient()
  if (currentClient == null) return

  currentClient.runOnClientShutdown.forEach((func) => func())
  currentClient.runOnClientShutdown = []

  client = null
}

export const createClient = (config: ClientConfig): AnalyticsClientType => {
  if (!browserIsCompatible()) {
    throw new Error(
      'This browser doesn’t support the APIs required for analytics-v2 client to work',
    )
  }

  if (client != null) {
    return client
  }
  client = new AnalyticsClient(config)
  return client
}
